import { Container, Hide, useToast } from '@chakra-ui/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useFetchEditContact, useSaveContact } from '../../../api/legacy/use-contacts'
import { Header, HeaderTitle } from '../../../components/Layout/Header'
import LegacyLayout from '../../../components/Layout/LegacyLayout'
import Page from '../../../components/Layout/Page'
import { CenterSpinner } from '../../../primitives'
import { CCFGButton } from '../../../primitives/Button/Button'
import { PageLayoutContent } from '../../../primitives/PageLayout/PageLayoutContent'
import { App } from '../../../Provider/App.Provider'
import { ERROR_TOAST, SUCCESS_TOAST } from '../../BusinessSettings/BusinessSettings.constant'
import { getLanguageSelection } from '../../BusinessSettings/GeneralPreferences/GeneralPreferences.utils'
import { ContactAdress } from '../new/ContactAdress'
import { ContactBasicInfo } from '../new/ContactBasicInfo'
import { EditContactResponseType, FormData as EditContactFormData } from './ContactEdit.types'
import { getCountrySelection } from './ContactEdit.utils'

interface ContactEditProps {
  onSubmit?: (data?: EditContactFormData) => void
}

const ContactEdit = ({ onSubmit }: ContactEditProps) => {
  const [initialFormValues, setInitialFormValues] = useState<
    EditContactResponseType | undefined | null
  >(null)
  const [contactFullName, setContactFullName] = useState<string>('')

  const toast = useToast()

  const contactHash = (document.querySelector('input[name="contact_hash"]') as HTMLInputElement)
    .value

  const contact = useFetchEditContact(contactHash)

  const saveMutation = useSaveContact(contactHash)

  const form = useForm<EditContactFormData>({
    defaultValues: {
      first_name: '',
      last_name: '',
      email_address: '',
      phone_number: '',
      company_name: '',
      website_url: '',
      default_language: { value: '', label: '' },
      notes: '',
      address_1: '',
      address_2: '',
      zip_code: '',
      state: '',
      city: '',
      country_code: { value: '', label: '' },
    },
  })

  const getEditContactFormData = useCallback(
    (data?: EditContactResponseType): EditContactFormData | undefined => {
      if (!data) return
      const {
        first_name,
        last_name,
        email_address,
        phone_number,
        company_name,
        website_url,
        default_language,
        notes,
        address_1,
        address_2,
        zip_code,
        state,
        city,
        country_code,
      } = data

      return {
        first_name: first_name,
        last_name: last_name,
        email_address: email_address,
        phone_number: phone_number,
        company_name: company_name,
        website_url: website_url,
        default_language: getLanguageSelection(default_language),
        notes: notes,
        address_1: address_1,
        address_2: address_2,
        zip_code: zip_code,
        state: state,
        city: city,
        country_code: getCountrySelection(country_code),
      }
    },
    [],
  )

  const defaultValues = useMemo(
    () => getEditContactFormData(contact.data),
    [contact.data, getEditContactFormData],
  )

  useEffect(() => {
    form.reset(defaultValues as EditContactFormData)
  }, [defaultValues, form])

  useEffect(() => {
    setInitialFormValues(contact.data)
    setContactFullName(`${contact.data?.first_name} ${contact.data?.last_name}`)
  }, [contact.data, setInitialFormValues])

  const handleSubmit = useCallback(
    (values: FormData) => {
      const contactId = contact.data?.contact_id

      const updatedValues = Object.keys(values).reduce((updatedData, fieldName) => {
        const isDefaultLanguageChanged =
          fieldName === ('default_language' as string) &&
          initialFormValues &&
          //@ts-ignore
          values[fieldName].value !== initialFormValues.default_language

        const isCountryCodeChanged =
          fieldName === 'country_code' &&
          initialFormValues &&
          //@ts-ignore
          values[fieldName].value !== initialFormValues.country_code

        if ((isDefaultLanguageChanged || isCountryCodeChanged) && initialFormValues) {
          //@ts-ignore
          updatedData[fieldName as keyof EditContactFormData] = values[fieldName]
        } else if (
          fieldName !== 'default_language' &&
          fieldName !== 'country_code' &&
          initialFormValues &&
          // @ts-ignore
          values[fieldName] !== initialFormValues[fieldName as keyof EditContactFormData]
        ) {
          // @ts-ignore
          updatedData[fieldName as keyof EditContactFormData] = values[fieldName]
        }

        return updatedData
      }, {} as EditContactFormData)

      saveMutation.mutate(
        { postArray: updatedValues, id: contactId },
        {
          onSuccess() {
            const currentFullName = `${updatedValues.first_name ?? contact.data?.first_name} ${
              updatedValues.last_name ?? contact.data?.last_name
            }`
            setContactFullName(currentFullName)
            toast({
              ...SUCCESS_TOAST,
              description: `Contact edited.`,
            })
          },
          onError() {
            toast({
              ...ERROR_TOAST,
              description: `Contact couldn't be edited.`,
            })
          },
        },
      )
    },
    [
      contact.data?.contact_id,
      contact.data?.first_name,
      contact.data?.last_name,
      initialFormValues,
      saveMutation,
      toast,
    ],
  )

  return (
    <FormProvider {...form}>
      <LegacyLayout>
        <Page
          mx="20px"
          as={'form'}
          role="form"
          onSubmit={form.handleSubmit((values: any) => {
            handleSubmit(values)
          })}
          renderHeader={
            <Header
              renderTitle={
                <HeaderTitle>
                  {!contact.data ? 'Edit Contact' : `Edit Contact - ${contactFullName}`}
                </HeaderTitle>
              }
              renderLeft={
                <CCFGButton
                  testId="edit-contact"
                  type="button"
                  onClick={() => (window.location.href = '/contacts')}
                >
                  Contacts
                </CCFGButton>
              }
              renderRight={
                <CCFGButton
                  testId="save-new-contact"
                  minW="135px"
                  type="submit"
                  isLoading={saveMutation.isLoading}
                >
                  Save
                </CCFGButton>
              }
            />
          }
        >
          <PageLayoutContent
            paddingTop={['20px', '30px']}
            paddingX={'0px'}
            testId="edit-contact-container"
          >
            <Hide above="md">
              <Container
                maxW="l"
                bg="brand.300"
                borderRadius={2}
                p={'5px'}
                textAlign="center"
                color={'navy.100'}
                fontSize="14px"
                mb="40px"
              >
                Edit Contact
              </Container>
            </Hide>
            {(contact.isLoading || contact.isPreviousData) && <CenterSpinner />}
            {contact.data && <ContactBasicInfo phoneNumber={contact.data.phone_number} />}
            {contact.data && <ContactAdress />}
          </PageLayoutContent>
        </Page>
      </LegacyLayout>
    </FormProvider>
  )
}

export default function ContactEditPage() {
  return (
    <App>
      <ContactEdit />
    </App>
  )
}
