import { ToastPosition } from "@chakra-ui/react"

type useToastOptions = {
  title: string,
  description?: string,
  position?: ToastPosition,
  variant?: string,
  duration?: number | null,
  isClosable?: boolean,
  status?: "error" | "info" | "warning" | "success" ,
}

export const SUCCESS_TOAST: useToastOptions = {
  title: 'Success',
  description: 'Successfully saved',
  position: 'top-right',
  variant: 'top-accent',
  duration: 4000,
  isClosable: true,
  status: 'success',
}

export const ERROR_TOAST: useToastOptions = {
  title: 'Error',
  description: 'Something bad happened :( ',
  position: 'top-right',
  variant: 'top-accent',
  duration: null,
  isClosable: true,
  status: 'error',
}
