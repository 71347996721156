import { Link as ChakraLink, LinkProps as BaseProps } from '@chakra-ui/react'
import { forwardRef } from 'react'
interface LinkProps extends BaseProps {
  testId: string
}
export const PrimaryLink = forwardRef(
  ({ testId, ...props }: LinkProps, ref: React.Ref<HTMLAnchorElement>) => {
    return (
      <ChakraLink
        {...props}
        color="var(--chakra-colors-teal-100) !important"
        ref={ref}
      />
    )
  },
)

export const Link = forwardRef(
  ({ testId, ...props }: LinkProps, ref: React.Ref<HTMLAnchorElement>) => {
    return (
      <ChakraLink
        {...props}
        ref={ref}
      />
    )
  },
)
